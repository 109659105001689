import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import css from "../../style/booking.module.css";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Dot,
} from "pure-react-carousel";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import { Tabs } from "@material-ui/core";
import { vehicleSpecsImageURL } from "../../store/constants/baseUrl";
import { useNavigate } from "react-router-dom";
import "pure-react-carousel/dist/react-carousel.es.css";

function useWindowSize() {
  const [size, setSize] = useState([]);
  useEffect(() => {
    try {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => {
        window.addEventListener("resize", handleResize);
      };
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, []);
  return size;
}

const SliderComponent = ({
  vehicles,
  vehicleTypeId,
  handleUpdateBookingObject,
}) => {
  // Menu state
  const [menuState, setmenuState] = useState(false);
  // getting the dimentions
  const [height, width] = useWindowSize();
  // getting history for routing
  const history = useNavigate();
  // find indexof current booking
  const getCurrentVehicleIndex = () => {
    const index = vehicles?.findIndex(
      (vehicle) => vehicle.id === vehicleTypeId
    );
    return index;
  };
  // returns bg color for button next
  const handleReturnBG = (active, inactive) => {
    try {
      if (getCurrentVehicleIndex() === vehicles?.length - 1) {
        return inactive;
      } else {
        return active;
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  // returns bg color for button back
  const handleReturnBG2 = (active, inactive) => {
    try {
      if (getCurrentVehicleIndex() === 0) {
        return inactive;
      } else {
        return active;
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  // Scroll the tabs
  const scrollTab = (index) => {
    const selectedVehicle = vehicles[index];
    if (selectedVehicle.id != vehicleTypeId) {
      handleUpdateBookingObject("vehicle", selectedVehicle, "13.1");
      handleUpdateBookingObject("vehicleTypeId", selectedVehicle.id, "13.2");
    }
    let len = vehicles.length;
    if (len > 1) {
      const tab = document.getElementsByClassName("MuiTabs-scroller");
      tab[0].scrollLeft = (index - len / 2 + 1) * 150;
    }
  };

  return (
    <div className={css?.sliderMainDiv}>
      <div className={css?.Level1Heading}>Select your vehicles</div>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={width > 600 ? 140 : 100}
        totalSlides={vehicles?.length}
        visibleSlides={1}
      >
        <div className={css?.sliderCard}>
          <div className={css?.sliderTopBar}>
            <Tabs
              scrollButtons="auto"
              variant="scrollable"
              className={css?.sliderTopBarButtons}
            >
              {vehicles?.map((vehicle, index) => (
                <Dot
                  slide={index}
                  id={`vehicleSliderDot${index}`}
                  onClick={() => scrollTab(index)}
                  style={{
                    borderBottom:
                      vehicle?.id === vehicleTypeId
                        ? "3px solid var(--secondary)"
                        : "none",
                    color:
                      vehicle?.id === vehicleTypeId
                        ? "var(--primary)"
                        : "silver",
                  }}
                  className={css?.vehicleButton}
                >
                  {vehicle?.title}
                </Dot>
              ))}
            </Tabs>
            <div className={css?.sliderDropdownBtn}>
              <div
                className={css?.slider3dotsBtn}
                onClick={() => setmenuState(!menuState)}
                style={{
                  backgroundColor: menuState
                    ? "var(--secondary)"
                    : "var(--primary)",
                }}
              >
                <i class="fas fa-circle"></i>
                <i class="fas fa-circle"></i>
                <i class="fas fa-circle"></i>
              </div>
            </div>
          </div>
          {menuState ? (
            <div className={css?.vehicleDropdown}>
              {vehicles?.map((vehicle) => (
                <div
                  className={css?.vehicleDropdownCard}
                  onClick={() => history(`/vehicle/${vehicle?.id}`)}
                >
                  <img
                    src={vehicleSpecsImageURL + vehicle?.pictureUrl}
                    alt={vehicles.title}
                    style={{ width: 150 }}
                  />
                  <div className={css?.vehiclesName2}>{vehicle?.title}</div>
                  <div className={css?.vehiclesDimentions2}>
                    {vehicle?.length +
                      "ft X " +
                      vehicle?.width +
                      "ft X " +
                      vehicle?.height +
                      "ft"}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <>
              <div className={css?.sliderButtonWrapper}>
                <ButtonBack
                  className={css?.sliderBackForward}
                  onClick={() => {
                    try {
                      if (getCurrentVehicleIndex() > 0) {
                        scrollTab(getCurrentVehicleIndex() - 1);
                      }
                    } catch (error) {
                      Sentry.captureException(error);
                      console.log(error);
                    }
                  }}
                  style={{
                    backgroundColor: handleReturnBG2(
                      "var(--secondary)",
                      "#c0faa5"
                    ),
                  }}
                >
                  <WestIcon />
                </ButtonBack>
              </div>
              <div className={css?.sliderWrapper}>
                <Slider className={css?.vehiclesSlider}>
                  {vehicles?.map((vehicles, index) => (
                    <Slide
                      index={index}
                      onClick={() => history(`/vehicle/${vehicles?.id}`)}
                    >
                      <div className={css?.vehiclesCard}>
                        <div>
                          <img
                            src={vehicleSpecsImageURL + vehicles?.pictureUrl}
                            className={css?.vehiclesImageImg}
                            alt={vehicles?.title}
                          />
                          <div className={css?.vehiclesName}>
                            {vehicles?.title}
                          </div>
                          <div className={css?.vehiclesDimentions}>
                            {vehicles?.length +
                              "ft X " +
                              vehicles?.width +
                              "ft X " +
                              vehicles?.height +
                              "ft"}
                          </div>
                        </div>
                      </div>
                    </Slide>
                  ))}
                </Slider>
              </div>
              <div className={css?.sliderButtonWrapper}>
                <ButtonNext
                  className={css?.sliderBackForward}
                  onClick={() => {
                    if (getCurrentVehicleIndex() < vehicles?.length - 1) {
                      scrollTab(getCurrentVehicleIndex() + 1);
                    }
                  }}
                  style={{
                    backgroundColor: handleReturnBG(
                      "var(--secondary)",
                      "#c0faa5"
                    ),
                  }}
                >
                  <EastIcon />
                </ButtonNext>
              </div>
            </>
          )}
        </div>
      </CarouselProvider>
    </div>
  );
};

export default SliderComponent;
