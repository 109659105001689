import React, { useState, useRef, useEffect } from "react";
import * as Sentry from "@sentry/react";
// importing the css
import css from "../../style/booking.module.css";
import { FaChevronDown } from "react-icons/fa";

const TimeSlot = ({ values, exactTime, type, handleUpdateBookingObject }) => {
  const [toggleDD, setToggleDD] = useState(false);
  const menuRef = useRef();
  const changeFunc = (val) => {
    try {
      handleUpdateBookingObject("exactTime", val, "11");
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  useEffect(() => {
    const closeDDMenuEvent = (e) => {
      if (!menuRef?.current?.contains(e.target)) {
        setToggleDD(false);
      }
    };
    document.addEventListener("click", closeDDMenuEvent);

    return () => {
      document.addEventListener("click", closeDDMenuEvent);
    };
  }, []);

  return (
    <div className={css?.dropdownMainDiv}>
      <div
        className={css?.dropdownTextDiv}
        onClick={() => setToggleDD(!toggleDD)}
        ref={menuRef}
      >
        {exactTime
          ? exactTime
          : `Select${
              type == "Delivery"
                ? " delivery"
                : type == "Moving"
                ? " pickup"
                : ""
            } time`}
        <FaChevronDown />
      </div>
      <div
        className={css?.DDOptions}
        style={{ display: toggleDD ? "block" : "none" }}
      >
        <div
          onClick={() => changeFunc("ASAP")}
          className={css?.DDOption}
          style={{
            backgroundColor:
              "ASAP" === exactTime ? "var(--secondary)" : "white",
          }}
        >
          ASAP
        </div>
        {values?.map((moves, index) =>
          moves?.exactTimeRange?.map((slot, i) => (
            <div
              key={index + i}
              onClick={() => changeFunc(slot)}
              className={css?.DDOption}
              style={{
                backgroundColor:
                  slot === exactTime ? "var(--secondary)" : "white",
              }}
            >
              {slot}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default TimeSlot;
