import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import css from "../../style/Home.module.css";
import { useNavigate } from "react-router";
import Places from "./DeliveryPlaces";
import MooversPlaces from "./MooversPlaces";
import cssToolTip from "../../style/booking.module.css";
import { tabPanels } from "../../Utilities/homeScreenUtilities";
import { CustomTooltip } from "../Tooltip/tooltip";

const TooltipText = ({ text }) => {
  return (
    <div className={cssToolTip?.tooltipMainDiv}>
      {text?.map((paragraph) => (
        <>
          <div className={cssToolTip?.tooltipHeading1}>
            {paragraph?.heading1}
          </div>
          <div className={cssToolTip?.tooltipHeading2}>
            {paragraph?.heading2}
          </div>

          <div
            className={cssToolTip?.tooltipText}
            style={{ marginBottom: "10px" }}
          >
            {paragraph.text2}
          </div>
          <div className={cssToolTip?.tooltipText}>{paragraph.text}</div>
          {paragraph?.bullets?.map((bullet) => (
            <div className={cssToolTip?.tooltipBullet}>
              <div className={cssToolTip?.tooltipDotBox}>
                <div className={cssToolTip?.tooltipDot}>.</div>
              </div>
              <div className={cssToolTip?.tooltipText}>{bullet}</div>
            </div>
          ))}
        </>
      ))}
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={css?.tabContentDiv}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  // save color for active tab
  const [activeTab, setActiveTab] = useState(1);

  //history for routing
  const history = useNavigate();

  const handleChange = (event, newValue) => {
    try {
      setValue(newValue);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  const handleSetActiveTab = (tabNo) => {
    setActiveTab(tabNo);
    localStorage.setItem("homeTabNo", tabNo);
  };

  const tabs = [
    { label: "Delivery", image: "delivery" },
    { label: "Moving", image: "moving" },
    { label: "Movers", image: "movers" },
    { label: "Business", image: "business" },
    { label: "Become a moover", image: "driver" },
  ];

  useEffect(() => {
    try {
      const tabNo = parseInt(localStorage.getItem("homeTabNo"));
      if (tabNo && tabNo !== activeTab) {
        handleSetActiveTab(tabNo);
        setValue(tabNo - 1);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, []);

  const handleBtnClickFunction = (btnNo, places, link) => {
    if (
      (["Delivery", "Moving", "Movers"].includes(places) && btnNo === 1) ||
      (btnNo === 2 && places === "Delivery")
    ) {
      localStorage.setItem("currentBookingType", places);
      history("/booking");
    } else if (places === "business" && btnNo === 1) {
      history(`/${places}`);
    } else if (places === "driver" && btnNo === 1) {
      window.location.href =
        "https://moversignup.moovez.ca/Driver/MooverSignUp";
    } else if (link && btnNo === 2) history(`/${link}`);
  };

  return (
    <Box className={css?.S1Slider}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          backgroundColor: "white",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          TabIndicatorProps={{ style: { background: "var(--primary)" } }}
        >
          {tabs.map((tab, index) => (
            <Tab
              label={tab.label}
              sx={{ textTransform: "none" }}
              {...a11yProps(index)}
              style={{
                color:
                  activeTab === index + 1 ? "var(--primary)" : "var(--text)",
                fontWeight: activeTab === index + 1 ? 500 : 300,
              }}
              onClick={() => handleSetActiveTab(index + 1)}
              icon={
                <img
                  className={css?.sliderTabIconImage}
                  src={`images/${tab.image}.svg`}
                  alt={tab.image}
                />
              }
            />
          ))}
        </Tabs>
      </Box>
      {tabPanels.map((tab_panel, index) => (
        <TabPanel value={value} index={index}>
          <div className={css?.sliderHeading}>
            {tab_panel.title}
            <CustomTooltip
              title={<TooltipText text={tab_panel.tooltip} />}
              placement="bottom"
            >
              <img
                src="images/tooltip.svg"
                style={{
                  display: "inline",
                  width: "25px",
                  cursor: "pointer",
                  fontWeight: 700,
                }}
                alt=""
              />
            </CustomTooltip>
          </div>
          <div className={css?.sliderTxt}>
            {tab_panel.text}
            {tab_panel.text2 ? (
              <>
                <br />
                {tab_panel.text2}
              </>
            ) : (
              <></>
            )}
          </div>
          {tab_panel.places === "Delivery" || tab_panel.places === "Moving" ? (
            <Places type={tab_panel.places} />
          ) : (
            <></>
          )}
          {tab_panel.places === "Movers" ? <MooversPlaces /> : <></>}
          <div className={css?.btnGroup}>
            <button
              onClick={() => handleBtnClickFunction(1, tab_panel.places)}
              className={`${css?.roundBtn} ${css?.successBtn}`}
              title={tab_panel.btn1Txt}
            >
              {tab_panel.btn1Txt}
            </button>
            <button
              onClick={() =>
                handleBtnClickFunction(2, tab_panel.places, tab_panel.btn2Link)
              }
              className={`${css?.roundBtn} ${css?.transparentBtn}`}
              title={tab_panel.btn2Txt}
            >
              {tab_panel.btn2Txt}
            </button>
          </div>
        </TabPanel>
      ))}
    </Box>
  );
}
