import css from "../../style/booking.module.css";

const BreakdownOfFare = ({ bookingTotalModel, distanceInKm }) => {
  const handleReturnVal = (val, symbol) => {
    return val ? symbol + val : "-";
  };
  return (
    <div>
      <div className={css?.costBreakdownTopText}>Price Breakdown</div>
      <div className={css?.costBreakdownHeading}>Pricing Details</div>
      <div className={css?.priceDiv}>
        <div className={css?.priceDivTop}>
          <div className={css?.priceBreakdownLine}>
            <span className={css?.priceBreakdownLeftWord}>Base Fare</span>
            <span className={css?.priceBreakdownRightWord}>
              {handleReturnVal(bookingTotalModel?.baseFare, "$")}
            </span>
          </div>
          <div className={css?.priceBreakdownLine}>
            <span className={css?.priceBreakdownLeftWord}>
              Distance {`(${distanceInKm}km)`}
            </span>
            <span className={css?.priceBreakdownRightWord}>
              {handleReturnVal(bookingTotalModel?.totalDistance, "$")}
            </span>
          </div>
          <div className={css?.priceBreakdownLine}>
            <span className={css?.priceBreakdownLeftWord}>Labour</span>
            <span className={css?.priceBreakdownRightWord}>
              {handleReturnVal(bookingTotalModel?.labourSurcharge, "$")}
            </span>
          </div>
          <div className={css?.priceBreakdownLine}>
            <span className={css?.priceBreakdownLeftWord}>Extra moover</span>
            <span className={css?.priceBreakdownRightWord}>
              {handleReturnVal(
                bookingTotalModel?.delivery_ExtraMooverCharge,
                "$"
              )}
            </span>
          </div>
          <div
            className={css?.priceBreakdownLine}
            style={{ fontWeight: "700" }}
          >
            <span className={css?.priceBreakdownLeftWord}>Subtotal</span>
            <span className={css?.priceBreakdownRightWord}>
              {handleReturnVal(bookingTotalModel?.totalChargeBTax, "$")}
            </span>
          </div>
        </div>

        <div className={css?.priceDivMid}>
          {/* <div className={css?.priceBreakdownLine}>
            <span className={css?.priceBreakdownLeftWord}>Booking fee</span>
            <span className={css?.priceBreakdownRightWord}>
              {handleReturnVal(bookingTotalModel?.serviceFee, "$")}
            </span>
          </div> */}
          <div className={css?.priceBreakdownLine}>
            <span className={css?.priceBreakdownLeftWord}>Service Fee</span>
            <span className={css?.priceBreakdownRightWord}>
              {handleReturnVal(bookingTotalModel?.totalServiceFee, "$")}
            </span>
          </div>
          <div className={css?.priceBreakdownLine}>
            <span className={css?.priceBreakdownLeftWord}>
              GST ( {bookingTotalModel?.totalTaxGstpstrate}% )
            </span>
            <span className={css?.priceBreakdownRightWord}>
              {handleReturnVal(bookingTotalModel?.gstandPst, "$")}
            </span>
          </div>
          <div className={css?.priceBreakdownLine}>
            <span className={css?.priceBreakdownLeftWord}>Discount</span>
            <span className={css?.priceBreakdownRightWord}>
              {handleReturnVal(bookingTotalModel?.discount, "")}
            </span>
          </div>
        </div>
        <div className={css?.priceDivBottom}>
          <div
            className={css?.priceBreakdownLine}
            style={{ fontStyle: "bold", fontSize: "20px" }}
          >
            <span className={css?.priceBreakdownLeftWord}>Total</span>
            <span className={css?.priceBreakdownRightWord}>
              {handleReturnVal(bookingTotalModel?.totalCharge, "$")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreakdownOfFare;
