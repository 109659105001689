import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import css from "../../style/Home.module.css";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { searchOptions } from "../../Utilities/mapUtilities";
import { generateBookingLogs } from "../../Utilities/general";

const Places = () => {
  // delivery obj
  const [bookingObject, setBookingObject] = useState({
    id: 0,
    pickupLocation: "",
    pickupLatitude: "",
    pickupLongitude: "",
    dropoffLocation: "",
    dropoffLatitude: "",
    dropoffLongitude: "",
    deliveryDate: "",
    type: "Movers",
    deliverySlotId: 1000,
    exactTime: "",
    labourNeeded: 1,
    totalOverweightItems: 0,
    estimatedTime: 0,
    pickupTotalFloors: 0,
    dropoffTotalFloors: 0,
    pickupMedium: "elevator",
    dropoffMedium: "elevator",
    distanceInKm: 0,
    durationInMins: 0,
    bookingSizeId: 1,
    dropOffPersonName: "",
    dropOffPersonPhone: "",
    pickUpPersonName: "",
    pickUpPersonPhone: "",
    dropOffInstructions: "",
    pickUpInstructions: "",
    tripStatus: "",
    userId: 0,
    numberOfHours: 1,
    stops: [],
  });
  // state to add a stop
  const [AddStopToggle, setAddStopToggle] = useState(false);
  // state to edit a stop
  const [editStopToggle, seteditStopToggle] = useState({
    status: false,
    address: "",
    index: "",
  });

  // current stop text
  const [currentStop, setcurrentStop] = useState("");
  // pickup address display name
  const [pickupAddressTxt, setpickupAddressTxt] = useState("");
  // dropOff address display name
  const [dropOffAddressTxt, setdropOffAddressTxt] = useState("");

  const handleEditStopSuccessfully = async (e) => {
    try {
      seteditStopToggle(false);
      const newStopsArray = bookingObject?.stops;
      const results = await geocodeByAddress(e);
      const latLng = await getLatLng(results[0]);
      newStopsArray[editStopToggle?.index] = {
        ...newStopsArray[editStopToggle?.index],
        stop: e,
        lat: latLng?.lat,
        long: latLng?.lng,
      };
      setBookingObject({ ...bookingObject, stops: newStopsArray });
      seteditStopToggle({ status: false, address: "", index: null });
      window.location.reload(false);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  const handleAddStop = async (e) => {
    try {
      if (e) {
        const results = await geocodeByAddress(e);
        const latLng = await getLatLng(results[0]);
        const newStop = {
          id: 0,
          stop: e,
          lat: latLng?.lat,
          long: latLng?.lng,
          personName: "",
          personPhone: "",
          instructions: "",
        };
        if (
          bookingObject?.stops &&
          Array.isArray(bookingObject?.stops) &&
          bookingObject?.stops?.length > 0
        ) {
          setBookingObject({
            ...bookingObject,
            stops: [...bookingObject?.stops, newStop],
          });
        } else {
          setBookingObject({
            ...bookingObject,
            stops: [newStop],
          });
        }
      }
      setcurrentStop("");
      setAddStopToggle(false);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  const handleDeleteStop = (index) => {
    try {
      const stopToDelete = bookingObject?.stops?.filter(
        (address, addIndex) => addIndex === index
      );
      setBookingObject({
        ...bookingObject,
        stops: bookingObject?.stops?.filter(
          (address, addIndex) => addIndex !== index
        ),
      });
      if (stopToDelete?.length > 0) {
        // dispatch(deleteStop(stopToDelete[0]?.id));
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  useEffect(() => {
    try {
      const temp = JSON.parse(localStorage.getItem("wemoovezMooversObject"));
      if (temp) {
        setBookingObject(temp);
        setpickupAddressTxt(temp?.pickupLocation);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, []);
  useEffect(() => {
    try {
      if (bookingObject.pickupLocation) {
        generateBookingLogs(null, bookingObject, "22", `wemoovezMooversObject`);
        localStorage.setItem(
          "wemoovezMooversObject",
          JSON.stringify(bookingObject)
        );
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [bookingObject]);
  return (
    <>
      <div className={css?.placesMainDiv}>
        <PlacesAutocomplete
          searchOptions={searchOptions}
          value={pickupAddressTxt}
          onChange={(e) => {
            try {
              if (!e) {
                setBookingObject({
                  ...bookingObject,
                  pickupLocation: "",
                });
              }
              setpickupAddressTxt(e);
            } catch (error) {
              Sentry.captureException(error);
              console.log(error);
            }
          }}
          onSelect={(e) => {
            try {
              setBookingObject({ ...bookingObject, pickupLocation: e });
              setpickupAddressTxt(e);
            } catch (error) {
              Sentry.captureException(error);
              console.log(error);
            }
          }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div className={css?.PlacesAutocomplete} style={{ paddingTop: 0 }}>
              <div className={css?.inputLine}>
                <div className={css?.PickupOverlapingDiv}>
                  <div className={css?.PlacesCircle}>.</div>
                </div>
                <input
                  className={css?.pickupInputTag}
                  {...getInputProps({
                    placeholder: "Enter location",
                  })}
                />
                <hr />
              </div>

              <div className={css?.suggestionsDiv}>
                {loading ? (
                  <div className={css?.suggestionsLine}>...loading</div>
                ) : null}

                {suggestions.map((suggestion) => {
                  const style = {
                    backgroundColor: suggestion.active
                      ? "var(--secondary)"
                      : "white",
                  };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        style,
                      })}
                      className={css?.suggestionsLine}
                    >
                      {suggestion?.description}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        {/* stops to cover */}
        {bookingObject?.stops?.map((address, index) => (
          <div className={css?.PlacesAutocomplete}>
            <div className={css?.inputLine}>
              <div className={css?.StopOverlapingDiv}>
                <div
                  className={css?.StopsCircle}
                  style={{ borderRadius: "0%" }}
                >
                  .
                </div>
              </div>
              <div className={css?.stopText}>{address?.stop}</div>
              <div
                onClick={() => handleDeleteStop(index)}
                className={css?.stopDeleteButton}
              >
                Remove
              </div>
            </div>
          </div>
        ))}
        {AddStopToggle && (
          <PlacesAutocomplete
            searchOptions={searchOptions}
            value={currentStop}
            onChange={(e) => {
              setcurrentStop(e);
            }}
            id="placesAutocompleteDropOff"
            onSelect={handleAddStop}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div className={css?.PlacesAutocomplete}>
                <div className={css?.inputLine}>
                  <div className={css?.StopOverlapingDiv}>
                    <div
                      className={css?.StopsCircle}
                      style={{ borderRadius: "0%" }}
                    >
                      .
                    </div>
                  </div>
                  <input
                    className={css?.pickupInputTag}
                    {...getInputProps({
                      placeholder: "Enter additional location",
                    })}
                  />
                </div>
                <div className={css?.suggestionsDiv}>
                  {loading ? (
                    <div className={css?.suggestionsLine}>...loading</div>
                  ) : null}

                  {suggestions.map((suggestion) => {
                    const style = {
                      backgroundColor: suggestion.active
                        ? "var(--secondary)"
                        : "white",
                    };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          style,
                        })}
                        className={css?.suggestionsLine}
                      >
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        )}
      </div>
      <div className={css?.addAStopButtonDiv}>
        <button
          onClick={() => {
            setAddStopToggle(!AddStopToggle);
            seteditStopToggle({
              address: "",
              index: "",
              status: false,
            });
          }}
          className={css?.stopBtn}
        >
          <span className={css?.stopPlusIcon}>
            {AddStopToggle ? (
              <i class="fas fa-minus"></i>
            ) : (
              <i class="fas fa-plus"></i>
            )}
          </span>
          {AddStopToggle ? "Remove" : "Add a"} stop
        </button>
      </div>

      {editStopToggle?.status && (
        <PlacesAutocomplete
          searchOptions={searchOptions}
          value={editStopToggle?.address}
          onChange={(e) => {
            seteditStopToggle({ ...editStopToggle, address: e });
          }}
          id="placesAutocompleteDropOff"
          onSelect={handleEditStopSuccessfully}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div className={css?.PlacesAutocomplete2}>
              <div className={css?.inputLine}>
                <i
                  class="fas fa-map-marker-alt"
                  style={{ margin: "auto" }}
                  aria-hidden="true"
                ></i>
                <input
                  className={css?.pickupInputTag}
                  {...getInputProps({
                    placeholder: "Enter additional location",
                  })}
                />
              </div>
              <div className={css?.suggestionsDiv}>
                {loading ? (
                  <div className={css?.suggestionsLine}>...loading</div>
                ) : null}

                {suggestions.map((suggestion) => {
                  const style = {
                    backgroundColor: suggestion.active
                      ? "var(--secondary)"
                      : "white",
                  };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        style,
                      })}
                      className={css?.suggestionsLine}
                    >
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      )}

      {/* 
        PLACES AUTO COMP END
         */}
    </>
  );
};

export default Places;
